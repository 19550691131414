import "./src/styles/global.css"
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './src/hooks/useAuth';

const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      {element}
    </AuthProvider>
  </QueryClientProvider>
);
