import React, { createContext, useState, useContext, useEffect } from "react";
import { navigate } from "gatsby";
import { useMutation } from "react-query";

type User = {
  email: string;
  password: string;
  name: string;
  surname: string;
};

type AuthContextType = {
  user: User | null;
  login: (loginData: LoginData) => void;
  logout: () => void;
  isLoginLoading: boolean;
};

const AuthContext = createContext<AuthContextType | null>(null);

type LoginData = {
  email: string;
  password: string;
};

const loginUser = async (loginData: LoginData) => {
  const apiUrl = process.env.GATSBY_API_URL;
  const response = await fetch(`${apiUrl}/auth/login`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(loginData),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Login failed");
  }

  return data;
};

const logoutUser = async () => {
  const apiUrl = process.env.GATSBY_API_URL;
  const response = await fetch(`${apiUrl}/auth/logout`, {
    method: "POST",
    credentials: "include",
  });

  localStorage.removeItem("api_token");
  localStorage.removeItem("user");

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Logout error");
  }

  return data;
};

type AuthProviderProps = { children: React.ReactNode };

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const storedUserJson = localStorage.getItem("user");
    if (storedUserJson) {
      const storedUser = JSON.parse(storedUserJson);
      setUser(storedUser);
    }
  }, []);

  const { mutate: loginMutate } = useMutation(loginUser, {
    onMutate: () => setIsLoading(true),
    onSuccess: (data) => {
      setUser(data);
      localStorage.setItem("user", JSON.stringify(data));
      navigate("/");
    },
    onError: () => setIsLoading(false),
    onSettled: () => setIsLoading(false),
  });

  const { mutate: logoutMutate } = useMutation(logoutUser, {
    onMutate: () => setIsLoading(true),
    onSuccess: () => {
      setUser(null);
      navigate("/");
    },
    onError: () => setIsLoading(false),
    onSettled: () => setIsLoading(false),
  });

  const login = (loginData: LoginData) => loginMutate(loginData);
  const logout = () => logoutMutate();

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        isLoginLoading: isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
